<template>
  <div class="Zhuanjia_c">
    <div class="Zhuanjia_ctop">
      <div class="Zhuanjia_ctop_center">
        <div class="Zhuanjia_ctop_l"><img :src="result.titlepic" alt="" /></div>
        <div class="Zhuanjia_ctop_r">
          <p>{{ result.title }}</p>
          <p v-html="result.fbtitle"></p>
        </div>
      </div>
    </div>
    <div class="Zhuanjia_cbox">
      <h3>专家介绍</h3>
      <div v-html="result.newstext"></div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      result: {},
      userinfo: {},
    };
  },
  created() {
    var that = this;
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    var id = that.$route.query.data;
    this.$axios({
      url: "/api/Expert/getExpertDetails",
      method: "post",
      data: {
        id: id,
      },
    }).then((res) => {
      if (res.data.code == 1) {
        that.result = res.data.result;
        document.title = res.data.result.title + " 专家简介";
        this.fenxiang(
          document.title,
          res.data.result.smalltext,
          res.data.result.titlepic
        );
      } else {
        console.log("请求失败");
      }
    });
  },
};
</script>
<style>
@import "../assets/css/zhuanjia.css";
</style>
